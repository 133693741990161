// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_sq d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_sr d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_ss d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_st d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_sv d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_sw d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_sx d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rs q_rs";
export var heroExceptionNormal = "s_rt q_rt";
export var heroExceptionLarge = "s_rv q_rv";
export var Title1Small = "s_q7 q_q7 q_qD q_qF";
export var Title1Normal = "s_q8 q_q8 q_qD q_qG";
export var Title1Large = "s_q9 q_q9 q_qD q_qH";
export var BodySmall = "s_rj q_rj q_qD q_qX";
export var BodyNormal = "s_rk q_rk q_qD q_qY";
export var BodyLarge = "s_rl q_rl q_qD q_qZ";